import { captureException } from '@sentry/nextjs';
import { Auth, AuthErrorCodes, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { formatSentryError, formatSentryMessage } from './telemetryHelpers';

export const getFirebaseErrorMessageFromErrorCode = (errorCode: string) => {
    let msg = 'An error occured, please try again.';

    // convert code to a human readable message (some messages can be found here: <https://firebase.google.com/docs/auth/admin/errors?hl=en>)
    switch (errorCode) {
        case AuthErrorCodes.EMAIL_EXISTS: {
            msg = 'This email already has an account. Please login instead of creating an account.';
            break;
        }
        case AuthErrorCodes.EXPIRED_POPUP_REQUEST: {
            msg = 'Expired popup request.';
            break;
        }
        case AuthErrorCodes.POPUP_BLOCKED: {
            msg = 'The login popup was blocked. Please enable popups in your browser.';
            break;
        }
        case AuthErrorCodes.POPUP_CLOSED_BY_USER: {
            msg = 'The login popup was closed by you. Please try again.';
        }
        case AuthErrorCodes.INVALID_PASSWORD: {
            msg = 'Incorrect password.';
            break;
        }
        case AuthErrorCodes.USER_CANCELLED: {
            msg = 'Login process was stopped by you.';
            break;
        }
        case AuthErrorCodes.USER_DELETED: {
            msg = 'User does not exist. Please create an account instead of logging in.';
            break;
        }
        case AuthErrorCodes.USER_DISABLED: {
            msg = 'Your account has been disabled.';
            break;
        }
        case AuthErrorCodes.USER_MISMATCH: {
            msg = 'Credential given does not correspond to you.';
            break;
        }
        case AuthErrorCodes.USER_SIGNED_OUT: {
            msg = 'You are signed out. Please re-sign in.';
            break;
        }
        case AuthErrorCodes.WEAK_PASSWORD: {
            msg = 'Your password is too weak. It must be at least six characters long.';
            break;
        }
        case AuthErrorCodes.INVALID_EMAIL: {
            msg = 'The email address is improperly formatted.';
            break;
        }
        case AuthErrorCodes.INTERNAL_ERROR: {
            msg = 'Internal Error.';
            break;
        }
        case AuthErrorCodes.TIMEOUT: {
            msg = 'Authentication timeout.';
            break;
        }
        case AuthErrorCodes.UNVERIFIED_EMAIL: {
            msg = 'Your email address is not verified. Please verify it.';
            break;
        }
        case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED: {
            msg = 'Web storage is unsupported. Please update or use a different browser.';
            break;
        }
        case AuthErrorCodes.ALREADY_INITIALIZED: {
            msg = 'Already initialized.';
            break;
        }
        case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER: {
            msg = 'Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.';
            break;
        }
        default: {
            break;
        }
    }
    return msg;
};

/** 
This function is used to determine if the user should sign in or sign up
@param auth - the firebase auth object
@param email - the email to check

@returns - the function to use to sign in or sign up, or null if the function fails
*/
export const getFirebaseSignInMethod = async (
    auth: Auth,
    email: string
): Promise<typeof signInWithEmailAndPassword | typeof createUserWithEmailAndPassword> => {
    let signInMethodsForEmail: string[];
    try {
        /* fetchSignInMethodsForEmail Explanation:
        * Returns and array of methods if the email exists.
        * Returns an empty array if the email does not exist.
        https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#fetchsigninmethodsforemail
        https://stackoverflow.com/a/66633881
        */
        signInMethodsForEmail = await fetchSignInMethodsForEmail(auth, email);
    } catch (error: any) {
        const { message, cause } = formatSentryError('fetchSignInMethodsForEmail firebase function failed', 'getFirebaseSignInMethod', error);
        captureException(new Error(message, cause));
        throw error;
    }

    const accountExists = signInMethodsForEmail.length > 0;

    if (accountExists) {
        if (signInMethodsForEmail.includes('password')) {
            // * return signin method
            return signInWithEmailAndPassword;
        } else if (signInMethodsForEmail.includes('google.com')) {
            // * we only support password and google.com sign in methods, but we should still handle the case where a user somehow has a different sign in method
            const errorMessage = 'This email is associated with a Google account. Please sign in with Google.';
            throw errorMessage;
        } else {
            // * we only support password and google.com sign in methods, but we should still handle the case where a user somehow has a different sign in method
            const message = formatSentryMessage('User tried to sign in with an email that has a deprecated authentication method', 'AuthForm');
            captureException(new Error(message));

            const errorMessage = 'This email is associated with a deprecated authentication method. Please contact contact@adauris.ai';
            throw errorMessage;
        }
    } else {
        // * return signup method
        return createUserWithEmailAndPassword;
    }
};
