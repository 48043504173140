import axios from 'axios';
import { GetCheckoutSessionUrlPayloadParams } from '../types/types';
import { captureException } from '@sentry/nextjs';
import { formatSentryError } from './telemetryHelpers';
import { FREE_14_DAY_PROFESSIONAL_TRIAL_COOKIE_NAME } from '../utils/constants/campaignConstants';
import { PLAN_VALUE } from '../utils/constants/featureFlagConstants';

export const getCheckoutSessionUrl = async (payload: GetCheckoutSessionUrlPayloadParams) => {
    try {
        const response = await axios.post(`/api/v2/checkout`, payload);
        return response.data;
    } catch (err) {
        const { message, cause } = formatSentryError('Error getting Stripe Checkout link', 'checkoutHelpers', err);
        captureException(new Error(message, cause));
    }
};

/**
 *
 * @param plan - pass in the plan value to get the trial period days for the specific plan. Undefined plan checks if the user has a free trial on any plan
 * @returns
 */
export const getSubscriptionTrialPeriodDays = (plan?: PLAN_VALUE): number | undefined => {
    try {
        const cookie = document.cookie;
        if (cookie.includes(FREE_14_DAY_PROFESSIONAL_TRIAL_COOKIE_NAME)) {
            // * if the user has a free trial, return 14 days
            if (!plan || plan === PLAN_VALUE.PROFESSIONAL) {
                return 14;
            }
        }

        return undefined;
    } catch (error) {
        const { message, cause } = formatSentryError('Error getting subscription trial period days', 'checkoutHelpers', error);
        captureException(new Error(message, cause));
    }
};
