import { Box, Button, Container, Stack, Text, VStack } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { registerPosthogEvent } from '../../helpers/postHogEventHelpers';
import { ALL_ROUTES, AuthPageType, BaseChildrenProps } from '../../types/types';
import { EVENT_NAME } from '../../utils/constants/postHogConstants';
import { useEffect, useMemo, useState } from 'react';
import { getSubscriptionTrialPeriodDays } from '../../helpers/checkoutHelpers';
import { PLAN_VALUE } from '../../utils/constants/featureFlagConstants';

interface AuthContainerProps extends BaseChildrenProps {
    showForgotPwLink?: boolean;
    authPageVersion?: AuthPageType;
}

/**
 * * Container for the auth modal & password reset page
 * @param param0
 * @returns
 */
export const AuthContainer = ({ showForgotPwLink, authPageVersion, children }: AuthContainerProps) => {
    const router = useRouter();
    const [subheadingText, setSubheadingText] = useState<string>('Your stories are waiting.');

    useEffect(() => {
        if (authPageVersion === 'signup') {
            const freeProTrialDays = getSubscriptionTrialPeriodDays(PLAN_VALUE.PROFESSIONAL);
            if (freeProTrialDays) {
                setSubheadingText(`Start your ${freeProTrialDays}-day free trial!`);
            } else {
                setSubheadingText('No credit card required.');
            }
        }
    }, [authPageVersion]);

    const onForgotPwClick = () => {
        registerPosthogEvent(EVENT_NAME.authModal_clickForgotPassword);
        router.push(ALL_ROUTES.RESET_PASSWORD);
    };

    const onSignInClick = () => {
        registerPosthogEvent(EVENT_NAME.authModal_clickAlreadyHaveAccount);
        router.push(ALL_ROUTES.SIGNIN);
    };

    const onSignUpClick = () => {
        registerPosthogEvent(EVENT_NAME.authModal_clickDoNotHaveAccount);
        router.push(ALL_ROUTES.SIGNUP);
    };

    return (
        <Container maxW="100%" w="2xl" centerContent py={{ base: '12' }} px={{ base: '2', sm: '8' }}>
            <Stack spacing="8" alignItems={'center'} w="100%">
                <VStack textAlign="center" w="100%">
                    <Text as={'h1'} fontSize={'heading2'}>
                        {authPageVersion === 'signup' ? 'Get AI audio of your stories' : 'Welcome back!'}
                    </Text>
                    <Text as={'h2'} fontSize={'subheading1'}>
                        {subheadingText}
                    </Text>
                </VStack>

                <Box
                    py={8}
                    px={{ base: '4', sm: '10' }}
                    borderRadius={'xl'}
                    bgColor={'brand.darkComponentBg'}
                    border={'1px'}
                    borderColor={'brand.darkComponentOutline'}
                    w="100%"
                >
                    {children}
                </Box>

                {authPageVersion === 'signup' ? (
                    <Text fontSize={'subheading2'}>
                        Already have an account?{' '}
                        <Button variant="link" fontSize={'subheading2'} onClick={onSignInClick}>
                            Sign in
                        </Button>
                    </Text>
                ) : (
                    <Text fontSize={'subheading2'}>
                        Don't have an account?{' '}
                        <Button variant="link" fontSize={'subheading2'} onClick={onSignUpClick}>
                            Sign up
                        </Button>
                    </Text>
                )}

                {showForgotPwLink && (
                    <Button variant="link" fontSize={'subheading2'} onClick={onForgotPwClick}>
                        Forgot password?
                    </Button>
                )}
            </Stack>
        </Container>
    );
};
